<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
    <el-form-item label="督办人eid" prop="urgeEid">
      <el-input v-model="dataForm.urgeEid" placeholder="督办人eid"></el-input>
    </el-form-item>
    <el-form-item label="督办人名字" prop="urgeName">
      <el-input v-model="dataForm.urgeName" placeholder="督办人名字"></el-input>
    </el-form-item>
    <el-form-item label="被督办人eid" prop="beUrgeEid">
      <el-input v-model="dataForm.beUrgeEid" placeholder="被督办人eid"></el-input>
    </el-form-item>
    <el-form-item label="被督办人名字" prop="beUrgeName">
      <el-input v-model="dataForm.beUrgeName" placeholder="被督办人名字"></el-input>
    </el-form-item>
    <el-form-item label="督办内容" prop="content">
      <el-input v-model="dataForm.content" placeholder="督办内容"></el-input>
    </el-form-item>
    <el-form-item label="督办类型 0：隐患" prop="type">
      <el-input v-model="dataForm.type" placeholder="督办类型 0：隐患"></el-input>
    </el-form-item>
    <el-form-item label="创建时间" prop="createDate">
      <el-input v-model="dataForm.createDate" placeholder="创建时间"></el-input>
    </el-form-item>
    <el-form-item label="最后修改时间" prop="updateDate">
      <el-input v-model="dataForm.updateDate" placeholder="最后修改时间"></el-input>
    </el-form-item>
    <el-form-item label="是否已删除" prop="isDeleted">
      <el-input v-model="dataForm.isDeleted" placeholder="是否已删除"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          urgeEid: '',
          urgeName: '',
          beUrgeEid: '',
          beUrgeName: '',
          content: '',
          type: '',
          createDate: '',
          updateDate: '',
          isDeleted: ''
        },
        dataRule: {
          urgeEid: [
            { required: true, message: '督办人eid不能为空', trigger: 'blur' }
          ],
          urgeName: [
            { required: true, message: '督办人名字不能为空', trigger: 'blur' }
          ],
          beUrgeEid: [
            { required: true, message: '被督办人eid不能为空', trigger: 'blur' }
          ],
          beUrgeName: [
            { required: true, message: '被督办人名字不能为空', trigger: 'blur' }
          ],
          content: [
            { required: true, message: '督办内容不能为空', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '督办类型 0：隐患不能为空', trigger: 'blur' }
          ],
          createDate: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ],
          updateDate: [
            { required: true, message: '最后修改时间不能为空', trigger: 'blur' }
          ],
          isDeleted: [
            { required: true, message: '是否已删除不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
        init(id) {
            this.dataForm.id = id || 0;
            this.visible = true;
            this.$nextTick(() => {
                this.$refs["dataForm"].resetFields();
                if (this.dataForm.id) {
                    this.$http.urge.info({ id: this.dataForm.id }).then((data) => {
                        if (data && data.code === 0) {
                            this.dataForm = data.data;
                        }
                    });
                }
            });
        },
        dataFormSubmit() {
            this.$refs["dataForm"].validate((valid) => {
                if (valid) {
                    let requestDate = this.dataForm;

                    if (this.dataForm.id) {
                        // 修改
                        this.$http.urge.update(requestDate).then((data) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: "修改操作成功",
                                    type: "success",
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        this.$emit("refreshDataList");
                                    },
                                });
                            } else {
                                this.$message.error(data.msg);
                            }
                        });
                    } else {
                        // 新增
                        requestDate.id = "";
                        this.$http.urge.save(requestDate).then((data) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: "添加成功",
                                    type: "success",
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        this.$emit("refreshDataList");
                                    },
                                });
                            } else {
                                this.$message.error(data.msg);
                            }
                        });
                    }
                }
            });
        },
    }
  }
</script>
